
.top_menu_container {
    position: fixed;
    background: linear-gradient(90deg, rgba(255,235,0,1) 50%, rgba(36,118,255,1) 50%);
    display: flex;
    width: 100%;
    padding-bottom: 5px;
    position: fixed;
    z-index: 999;
}

.top_menu {
    margin-top: -5px;
    width: 100%;
    display: flex;
    padding-top: 15px;
    padding-bottom: 5px;
    background: white;
    justify-content: center;
}

.menu_item {
    margin-left: 4vmin;
    margin-right: 4vmin;
    cursor: pointer;
    text-decoration: none;
    color: #002d76;
    font-weight: bold;
}
  