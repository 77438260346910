@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700;800&display=swap);

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

body,
html {
    height: 100%
}

a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
caption,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
    background-position: 0 0;
    background-repeat: no-repeat;
    border: 0;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    padding: 0;
    text-align: left;
    outline: none;
    text-decoration: none
}

:link img,
:visited img,
a img {
    border: 0
}

.header {
  padding-top: 30px;
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

ul {
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: ""
}

h1 {
    color: #fff;
    font-weight: 800
}

h1,
h2 {
    font-family: Montserrat, sans-serif;
    font-size: 32px;
    line-height: 44px;
    font-style: normal;
    text-transform: none
}

h2 {
    color: #00348c;
    font-weight: 500
}

h2 b {
    font-weight: 800
}

h3 {
    font-family: Montserrat, sans-serif;
    color: #00348c;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    font-style: normal;
    text-transform: none
}

h3 b {
    font-weight: 800
}

h4 {
    color: #fff;
    font-size: 48px;
    line-height: 52px;
    font-weight: 800
}

h4,
h5 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    text-transform: none
}

h5 {
    color: #00348c;
    font-size: 26px;
    line-height: 32px;
    font-weight: 700
}

.lang {
    height: 2.75rem;
    border: 2px solid #ffc83f;
    border-radius: 6px
}

.lang,
.lang_link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.lang_link {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    padding: 0 1.5rem
}

.lang_link.link {
    font-family: Montserrat, sans-serif;
    color: #ffc83f;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    font-style: normal;
    text-transform: none
}

.lang_link.link.link_active {
    color: #005bdf;
    background: #ffc83f
}

.lang_link.link.link_active:hover {
    background: #ffc83f
}

.lang_link.link:hover {
    background: rgba(255, 200, 63, .125)
}

.button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    height: 3.25rem;
    padding: 0 2.75rem;
    white-space: nowrap;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #ffc83f;
    border: none;
    border-radius: 6px;
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    font-family: Montserrat, sans-serif;
    color: #002d76;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    font-style: normal;
    text-transform: none
}

.button:hover {
    background: #ffc023
}

.container {
    width: 100%;
    max-width: 79rem;
    padding: 0 2rem;
    margin: 0 auto
}

.list_item {
    margin-bottom: 1rem
}

.list_icon,
.list_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.list_icon {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 4.5rem;
    min-height: 4.5rem;
    margin-right: 1.25rem;
    background: rgba(255, 200, 63, .1);
    border-radius: 50%
}

.wrap-table {
    overflow: hidden;
    border-radius: .75rem
}

table {
    width: 100%;
    background: #f4f7fd;
    font-family: Montserrat, sans-serif;
    color: #00348c;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-style: normal;
    text-transform: none
}

table b {
    font-weight: 800
}

table tr:nth-child(2n) {
    background: #fff
}

table tr:first-child td {
    padding-top: 2rem
}

table tr:last-child td {
    padding-bottom: 2rem
}

table td {
    padding: .5rem 3.75rem
}

.section_navigation {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 149px
}

.section_navigation .container {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%
}

.section_navigation .container,
.section_navigation .navigation__social-lang {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.section_navigation .navigation__social-lang {
    margin-bottom: .75rem
}

.section_navigation .navigation_lang {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content
}

.section_navigation .navigation__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 2rem
}

.section_navigation .navigation__social .social__link {
    margin-right: 1rem;
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out
}

.section_navigation .navigation__social .social__link:last-child {
    margin-right: 0
}

.section_navigation .navigation__social .social__link:hover {
    opacity: .75
}

.section_navigation .navigation__social .social__icon img {
    display: block;
    height: 40px
}

.section_first {
    height: 37.5rem;
    overflow: hidden;
    background: url(../../assets/first-bg.jpg);
    background-position: 100%;
    background-size: cover
}

.section_first .container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

.section_first .first_title {
    max-width: 820px;
    margin-top: 4rem
}

.section_help {
    padding-top: 9.25rem;
    padding-bottom: 9.25rem;
    /*background: url(../../assets/help-bg.jpg);*/
    background-position: 50%;
    background-size: cover
}

.section_help .container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1.25rem
}

.section_help .container .wrap-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-column: 1/6;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.section_help .container .wrap-img .help_map {
    -webkit-transform: translate(-3rem);
    -ms-transform: translate(-3rem);
    transform: translate(-3rem)
}

.section_help .container .wrap-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-column: 7/-1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.section_help .container .wrap-text .text_first {
    margin-bottom: 1rem
}

.section_help .container .wrap-text .text_secondary {
    margin-bottom: 2rem
}

.section_necessary {
    padding-top: 10rem;
    padding-bottom: 10rem
}

.section_necessary .container {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1.25rem
}

.section_necessary .necessary_title {
    grid-row: 1/2;
    grid-column: 1/9;
    margin: auto 0
}

.section_necessary .list.list_first {
    grid-row: 2/3;
    grid-column: 1/5
}

.section_necessary .list.list_second {
    grid-row: 2/3;
    grid-column: 5/9
}

.section_necessary .list.list_third {
    grid-row: 1/3;
    grid-column: 9/-1
}

.section_bills {
    padding-top: 10rem;
    padding-bottom: 10rem;
    background: url(../../assets/bills-bg.jpg);
    background-position: 50%;
    background-size: cover
}

.section_bills .container {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1.25rem;
    grid-row-gap: 3rem
}

.section_bills .wrap-currency {
    display: grid;
    grid-template-columns: 5rem 1fr;
    grid-column: 3/-3;
    grid-gap: 1.25rem
}

.section_bills .wrap-currency.wrap-currency_public-union {
    grid-template-columns: 1fr
}

.section_bills .bills_title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-column: 1/-1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center
}

.section_bills .wrap-table {
    border: 1px solid rgba(0, 52, 140, .1)
}

.section_bills .currency-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    padding: 1rem 0;
    background: #fff;
    background: #f4f7fd;
    border: 1px solid rgba(0, 52, 140, .15);
    border-radius: .75rem
}

.section_contacts {
    padding-top: 4rem;
    padding-bottom: 4rem
}

.section_contacts .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.section_contacts .contact__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .25rem .75rem .25rem .5rem;
    margin: .75rem;
    background: rgba(0, 91, 223, .075);
    border-radius: 12px;
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out
}

.section_contacts .contact__wrap:hover {
    background: rgba(0, 91, 223, .15)
}

.section_contacts .contact__icon img {
    display: block;
    height: 36px
}

.section_contacts .contact__value {
    margin-left: .5rem;
    white-space: nowrap;
    font-family: Montserrat, sans-serif;
    color: #00348c;
    font-size: 17px;
    line-height: 40px;
    font-weight: 600;
    font-style: normal;
    text-transform: none
}

.footer {
    position: relative;
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
    background: #002d76;
    border-top: .5rem solid #2476ff;
    border-bottom: .5rem solid #2476ff
}

.footer:after,
.footer:before {
    position: absolute;
    top: -.5rem;
    right: 0;
    display: block;
    width: 50%;
    height: .5rem;
    content: "";
    background: #ffeb00
}

.footer:before {
    top: auto;
    bottom: -.5rem
}

.footer .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.footer h4 {
    text-align: center
}

@media screen and (max-width:1200px) {
    h1 {
        line-height: 40px
    }

    h1,
    h2 {
        font-size: 28px
    }

    h2 {
        line-height: 36px
    }

    h3,
    h5 {
        font-size: 22px;
        line-height: 28px
    }

    .button {
        height: 3rem
    }

    .section_first {
        background-position: right -8rem center
    }

    .section_help .container .wrap-img .help_map {
        max-width: 120%;
        -webkit-transform: translate(-2rem);
        -ms-transform: translate(-2rem);
        transform: translate(-2rem)
    }
}

@media screen and (max-width:1100px) {
    .section_contacts .contact__icon img {
        height: 40px
    }

    .section_contacts .contact__value {
        font-size: 20px;
        line-height: 32px
    }
}

@media screen and (max-width:1000px) {
    h3 {
        font-size: 18px;
        line-height: 24px
    }

    h4 {
        font-size: 36px;
        line-height: 44px
    }

    table td {
        padding-right: 2.5rem;
        padding-left: 2.5rem
    }

    .section_first .first_title {
        max-width: 60vw
    }

    .section_first .first_title br {
        display: none
    }

    .section_help .container .wrap-img .help_map {
        max-width: 120%;
        -webkit-transform: translate(-1rem);
        -ms-transform: translate(-1rem);
        transform: translate(-1rem)
    }

    .section_necessary .container {
        grid-row-gap: 0
    }

    .section_necessary .necessary_title {
        grid-row: 1/2;
        grid-column: 1/-1;
        margin-bottom: 2rem;
        text-align: center
    }

    .section_necessary .list.list_first {
        grid-row: 2/3;
        grid-column: 2/6
    }

    .section_necessary .list.list_second {
        grid-row: 3/4;
        grid-column: 2/6
    }

    .section_necessary .list.list_third {
        grid-row: 2/4;
        grid-column: 8/-1
    }

    .section_bills {
        padding-top: 7rem;
        padding-bottom: 7rem
    }

    .section_bills .wrap-currency {
        grid-column: 2/-2
    }
}

@media screen and (max-width:900px) {
    .section_first {
        background-position: right -10rem center
    }

    .section_contacts .container {
        display: grid;
        grid-gap: 1rem;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .section_contacts .contact__wrap {
        padding: .5rem .75rem .5rem .5rem;
        margin: .25rem
    }

    .section_contacts .contact__value {
        font-size: 24px;
        line-height: 40px
    }
}

@media screen and (max-width:800px) {

    h1,
    h2 {
        font-size: 24px;
        line-height: 32px
    }

    h5 {
        font-size: 18px;
        line-height: 24px
    }

    .lang {
        height: 2.25rem;
        border: 2px solid #ffc83f
    }

    .lang_link {
        padding: 0 1rem
    }

    .lang_link.link {
        font-size: 16px
    }

    .list_item {
        margin-bottom: .5rem
    }

    .list_icon {
        width: 4rem;
        min-width: 4rem;
        height: 4rem;
        min-height: 4rem;
        margin-right: .75rem
    }

    .list_icon img {
        width: 56px;
        height: 56px
    }

    .section_navigation .navigation__social-lang {
        margin-bottom: .5rem
    }

    .section_navigation .navigation__social {
        padding-right: 1rem
    }

    .section_navigation .navigation__social .social__link {
        margin-right: .865rem
    }

    .section_navigation .navigation__social .social__icon img {
        height: 36px
    }

    .section_help {
        padding-top: 7rem;
        padding-bottom: 7rem
    }

    .section_help .container .wrap-img {
        grid-row: 1/2;
        grid-column: 1/-1;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .section_help .container .wrap-img .help_map {
        max-width: 100%;
        -webkit-transform: translate(0);
        -ms-transform: translate(0);
        transform: translate(0)
    }

    .section_help .container .wrap-text {
        grid-row: 2/3;
        grid-column: 1/-1
    }

    .section_necessary {
        padding-top: 7rem;
        padding-bottom: 7rem
    }

    .section_necessary .list.list_first,
    .section_necessary .list.list_second {
        grid-column: 1/6
    }

    .section_bills .container {
        grid-row-gap: 2rem
    }

    .section_bills .wrap-currency {
        grid-column: 1/-1
    }

    .section_bills .bills_title {
        margin-bottom: -.5rem
    }

    .footer {
        padding-top: 6rem;
        padding-bottom: 6rem
    }
}

@media screen and (max-width:700px) {
    h4 {
        font-size: 28px;
        line-height: 36px
    }

    .section_navigation {
        height: 132px
    }

    .section_navigation .navigation_logo {
        max-height: 64px
    }

    .section_first {
        background-position: right -16rem center
    }
}

@media screen and (max-width:640px) {
    .section_bills {
        padding-top: 5rem;
        padding-bottom: 5rem
    }

    .section_bills .wrap-currency {
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
        grid-gap: .5rem
    }

    .section_bills .currency-icon {
        padding: .5rem 0
    }

    .section_bills .currency-icon img {
        width: 32px
    }
}

@media screen and (max-width:600px) {
    table {
        font-size: 14px;
        line-height: 20px
    }

    table td {
        padding-right: 1.5rem;
        padding-left: 1.5rem
    }

    .section_navigation .navigation__social {
        padding-right: .75rem
    }

    .section_navigation .navigation__social .social__link {
        margin-right: .625rem
    }

    .section_navigation .navigation__social .social__icon img {
        height: 32px
    }

    .section_help,
    .section_necessary {
        padding-top: 5rem;
        padding-bottom: 5rem
    }

    .section_necessary .list.list_first,
    .section_necessary .list.list_second {
        grid-column: 3/-3
    }

    .section_necessary .list.list_third {
        grid-row: 4/5;
        grid-column: 3/-3
    }
}

@media screen and (max-width:520px) {
    .section_navigation .navigation__social-lang {
        position: absolute;
        top: 2.5rem;
        right: 2rem;
        display: grid;
        grid-template-rows: auto auto;
        margin-bottom: 0
    }

    .section_navigation .navigation_lang {
        grid-row: 1/2;
        grid-column: 1/-1;
        margin-bottom: 1rem
    }

    .section_navigation .navigation__social {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        grid-row: 2/3;
        grid-column: 1/-1;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        padding-right: 0
    }

    .section_navigation .navigation__social .social__link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 40px;
        margin-right: 0;
        margin-bottom: .75rem
    }

    .section_navigation .navigation__social .social__link:first-child {
        margin-bottom: .5rem
    }

    .section_navigation .navigation__social .social__icon img {
        height: 36px
    }

    .section_first .first_title {
        margin-top: 6rem;
        position: absolute;
        bottom: 2rem;
        max-width: 87vw;
        margin-top: 0
    }
}

@media screen and (max-width:500px) {
    h2 br {
        display: none
    }

    .section_first {
        max-height: 100vh
    }

    .section_contacts .contact__value {
        font-size: 20px;
        line-height: 32px
    }
}

@media screen and (max-width:440px) {
    h1 {
        font-size: 22px;
        line-height: 30px
    }

    .section_navigation .navigation__social-lang {
        right: 1.5rem
    }
}

@media screen and (max-width:420px) {

    .section_necessary .list.list_first,
    .section_necessary .list.list_second,
    .section_necessary .list.list_third {
        grid-column: 2/-2
    }

    .section_bills .container {
        padding: 0 .5rem
    }
}

@media screen and (max-width:400px) {
    h1 {
        font-size: 20px;
        line-height: 28px
    }

    h4 {
        font-size: 28px;
        line-height: 36px
    }

    .container {
        padding: 0 1.5rem
    }

    table tr:first-child td {
        padding-top: 1.5rem
    }

    table tr:last-child td {
        padding-bottom: 1.5rem
    }

    table td {
        padding-right: 1rem;
        padding-left: 1rem
    }

    .section_navigation {
        height: 120px
    }
}

@media screen and (max-width:380px) {
    .section_navigation .navigation_logo {
        max-height: 57px
    }
}

@media screen and (max-width:360px) {
    h4 {
        font-size: 24px;
        line-height: 32px
    }

    .container {
        padding: 0 1rem
    }

    table {
        font-size: 12px;
        line-height: 16px
    }

    .section_navigation .navigation__social-lang {
        right: 1rem
    }

    .section_navigation .navigation__social .social__icon img {
        height: 32px
    }
}

.iban {
    font-weight: 400px;
}